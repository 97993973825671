export const New = () => {
  return (
    <div className="new">
      <p className="new__title">What's news?</p>
      <p className="new__description">
        A seção de bônus e promoções foi atualizada.
      </p>
      <p className="new__description">
        A localização da agência, caixa eletrônico ou terminal mais próximo é
        adicionada ao mapa.
      </p>
      <p className="new__description">
        O suporte para Face ID e Touch ID foi adicionado.
      </p>
      <p className="new__description">
        Corrigido um problema com notificações push que alguns usuários do
        Android 10 estavam enfrentando.
      </p>
      <p className="new__description">
        Corrigido um problema em que o texto nas Configurações era muito pequeno
        para usuários do Android.
      </p>
    </div>
  );
};
