import starGrey from "../../assets/starGrey.png";
import starGreen from "../../assets/starGreen.png";
import review1 from "../../assets/review1.jpeg";
import review2 from "../../assets/review2.jpeg";
import review3 from "../../assets/review3.jpeg";
import review4 from "../../assets/review4.jpeg";
import review5 from "../../assets/review5.jpg";
import review6 from "../../assets/review6.jpg";
import like from "../../assets/like.svg";
import user from "../../assets/user.png";
import { useState } from "react";

export const Reviews = () => {
  const [showReview, setShowReview] = useState(false);
  return (
    <>
      <p className="reviews__title">Rating and reviews</p>
      <div className="reviews__wrapper">
        <div>
          <p className="reviews__score">4.8</p>
          <div className="star__wrapper">
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGrey} alt="grey star" className="green__star" />
          </div>
          <p className="user__wrapper">
            General
            <img src={user} alt="user icon" width={16} height={16} />
          </p>
          <p className="reviews__number">4625</p>
        </div>
        <div>
          <ul className="lines__list">
            <li className="lines__item">
              <p className="numbers">5</p>
              <div className="first__line desktop_one">
                <div className="first__line--green"></div>
              </div>
            </li>
            <li className="lines__item">
              <p className="numbers">4</p>
              <div className="first__line desktop_two">
                <div className="second__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">3</p>
              <div className="first__line desktop_three">
                <div className="third__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">2</p>
              <div className="first__line desktop_four">
                <div className="fourth__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">1</p>
              <div className="first__line desktop_five">
                <div className="fourth__line--green"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review1}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Vítor Gabriel Casado</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGrey}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">07.08.2024</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            Melhor projeto que já vi! Bônus de 20k caiu imediatamente!! O
            dinheiro foi retirado para o cartão instantaneamente! Ótimo projeto,
            eu jogo com frequência!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">MilleniumCasino</p>
            <p className="review__description">
              Obrigado pelo seu comentário! É muito importante para nós que
              nossos clientes gostem do nosso projeto. Nossa equipe fará de tudo
              para garantir que você goste de usar nosso produto!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review2}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Rafael Soares</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">06.08.2024</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            Mais uma vez, o cassino criou um projeto de bomba sem precedentes. O
            melhor entre os concorrentes. Slots incríveis, bônus incríveis e
            Deus te abençoe. Reabasteci 50 € para me divertir e retirei 2.300 €
            para um cartão bancário! Excelente projeto, tenho certeza que vai
            explodir o mercado!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">MilleniumCasino</p>
            <p className="review__description">
              Obrigado pelo seu feedback honesto e positivo. Estamos
              constantemente aprimorando nosso projeto, ficamos muito felizes
              que você tenha gostado!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review3}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Ricardo Batista</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGrey}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">05.08.2024</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            Normalmente não confio em todos esses cassinos! Mas este aplicativo
            é ótimo! Depositei 70€ e ganhei 8.500€ em 25 minutos. Vou continuar
            jogando e ganhando dinheiro!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">MilleniumCasino</p>
            <p className="review__description">
              Matthew Gibson, obrigado pelo seu bom comentário! Jogue e
              divirta-se!
            </p>
          </div>
        </div>
      </div>
      {showReview && (
        <>
          <div className="item__wrapper">
            <img
              src={review4}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Henrique Cavalheiro</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">04.08.2024</p>
                </div>
                <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div>
              </div>
              <p className="item__text">
                Não esperava que a galera fizesse um projeto tão bom! Respeito e
                honra! O cassino mais generoso da minha vida! Estou surpreso)
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">MilleniumCasino</p>
                <p className="review__description">
                  Nathan Anderson, obrigado pelo comentário. Nosso projeto foi
                  criado apenas para transmitir emoções às pessoas. Faremos todo
                  o possível para te deixar feliz! Boa sorte para você!
                </p>
              </div>
            </div>
          </div>
          {/* <div className="item__wrapper">
            <img
              src={review5}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Максим Ефремов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">08.04.2024</p>
                </div> */}
          {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
          {/* </div>
              <p className="item__text">
                Насыпало за 5 дней игры очень круто! Спасибо за офигенное
                Kazino!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Kazino</p>
                <p className="review__description">
                  Максим Ефремов, спасибо за отзыв! Нам очень важно, чтобы наши
                  клиенты получали удовольствие от нашего проекта.
                </p>
              </div>
            </div>
          </div>
          <div className="item__wrapper">
            <img
              src={review6}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Олег Мартынов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">05.04.2024</p>
                </div> */}
          {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
          {/* </div>
              <p className="item__text">
                У меня нет слов. С 500 рублей поднял 7000 за пару часов ;))
                Давно мне так не фартило. Лучшее Kazino в которое я играл!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Kazino</p>
                <p className="review__description">
                  Олег Мартынов, благодарим вас за положительный отзыв.
                  Стараемся удовлетворять наших клиентов!
                </p>
              </div>
            </div>
          </div> */}
        </>
      )}
      <button
        type="button"
        className="reviews__button"
        onClick={() => {
          setShowReview((prevState) => !prevState);
        }}
      >
        {showReview ? "Hide reviews" : "All reviews"}
      </button>
    </>
  );
};
